<template>
  <div>
    <div id="navbar">
      <!-- <router-view /> -->
      <Header />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  name: "Navigation",
  components: {
    Header,
  },
};
</script>

<style scoped>
</style>