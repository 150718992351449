
import "./set-public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./plugin/store/main.js";
import singleSpaVue from "single-spa-vue";
import Vuetify from "vuetify";
/* import "vuetify/dist/vuetify.min.css"; */

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00a5ff",
      },
    },
  },
});

Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
